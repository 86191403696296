<template>
  <div class="page-box">
    <div class="item-box box">
      <div class="item-img">
        <img
          :src="detail.courseImg"
          alt=""
        >
      </div>
      <div class="item-text-a">
        {{ detail.courseName || '-' }}
      </div>
      <div class="item-text-b">
        第{{ detail.period || 0 }}课/共 {{ detail.totalPeriod || 0 }}课次
      </div>
      <div class="item-text-c">
        学员：{{ detail.childName || '-' }}
      </div>
      <div
        class="log-btn"
        @click="goLogs"
      >
        上课记录
      </div>
    </div>
    <div
      v-if="detail.status === 999"
      class="time-box box"
    >
      <div class="text-a">
        下节课时间
      </div>
      <div class="text-b">
        {{ detail.nextTime || '--' }}
      </div>
    </div>
    <div class="box box-a">
      <div
        v-if="detail.status === 1"
        class="van-cell"
        @click="show = true"
      >
        <div
          class="van-cell__title"
        >
          <span>核销码</span>
        </div><div
          class="van-cell__value"
        >
          <van-image :src="require('@/assets/img/qrcode-logo.png')"></van-image>
        </div>
      </div>
      <div
        v-else
        class="van-cell"
      >
        <div
          class="van-cell__title"
        >
          核销时间
        </div><div
          class="van-cell__value"
        >
          {{ detail.finalFinishTime || '-' }}
        </div>
      </div>
      <div
        class="van-cell"
      >
        <div
          class="van-cell__title"
        >
          家长姓名
        </div><div
          class="van-cell__value"
        >
          {{ detail.userName || '-' }}
        </div>
      </div>
      <div
        class="van-cell"
      >
        <div
          class="van-cell__title"
        >
          学员姓名
        </div><div
          class="van-cell__value"
        >
          {{ detail.childName || '-' }}
        </div>
      </div>
    </div>
    <!-- box-b -->
    <div class="box box-b">
      <div
        class="van-cell"
      >
        <div
          class="van-cell__title"
        >
          <span>商品金额</span>
        </div><div
          class="van-cell__value"
        >
          <span>￥{{ detail.amount || 0 }}</span>
        </div>
      </div>
      <div
        class="van-cell"
      >
        <div
          class="van-cell__title"
        >
          <span>订单编号</span>
        </div><div
          class="van-cell__value"
        >
          {{ detail.orderNo || '-' }}
        </div>
      </div>
      <div
        class="van-cell"
      >
        <div
          class="van-cell__title"
        >
          创建时间
        </div>
        <div
          class="van-cell__value"
        >
          {{ detail.createTime || '-' }}
        </div>
      </div>
      <div
        class="van-cell"
      >
        <div
          class="van-cell__title"
        >
          付款时间
        </div>
        <div
          class="van-cell__value"
        >
          {{ detail.finishTime || '-' }}
        </div>
      </div>
    </div>
    <!-- box-b end -->
    <div
      v-if="detail.status == 1"
      class="box-btn"
      @click="btnAction"
    >
      我要退课
    </div>
    <!-- 核销码弹窗 -->
    <van-popup v-model="show">
      <div class="popup-box box">
        <van-image :src="detail.useImg || require('@/assets/img/qrcode-logo.png')"></van-image>
        <div class="text-a">
          请扫核销码
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from 'vant';

export default {
  name: 'OrderDetail',
  components: {
  },
  data () {
    return {
      show: false,
      detail: {}
    }
  },
  computed: {
  },
  created () {
    this.getDetail()
  },
  methods: {
    async getDetail () {
      let data = {
        id: this.$route.query.orderNo || ''
      }
      let res = await this.$http.userApi.userOrderDetail(data)
      if (res.code == 200) {
        this.detail = res.msg || {}
      }
      console.log(res)
    },
    goLogs () {
      this.$router.push('/order/logs?orderNo=' + this.$route.query.orderNo || '')
    },
    btnAction () {
      // console.log(showConfirmDialog, 'showConfirmDialogshowConfirmDialog')
      Dialog.confirm({
        title: '确认退课',
        // message: '确认退课？',
      })
        .then(() => {
          // on confirm
          let data = {
            orderNo: this.$route.query.orderNo || ''
          }
          this.$http.userApi.personalRefund(data).then((res) => {
            console.log(res, 'res');
            if (res.code == 200) {
              this.$toast.success('已提交退课申请，请等待审核')
              this.getDetail()
            }
          })
        })
        .catch(() => {
          // on cancel
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding: 15px;
  .item-box {
    width: 346px;
    padding: 0 15px;
    margin: 0 auto;
    min-height: 131px;
    border-radius: 17px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 18px;
    background: #ECF1FF;
    .item-img {
      width: 155px;
      height: 103px;
      border-radius: 17px;
      position: absolute;
      left: 15px;
      bottom: 15px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-text-a {
      line-height: 27px;
      position: absolute;
      left: 178px;
      top: 12px;
      font-weight: 600;
      color: #3D4258;
      font-size: 18px;
    }
    .item-text-b {
      color: #8F92A9;
      font-size: 13px;
      position: absolute;
      left: 178px;
      top: 44px;
      line-height: 19px;
    }
    .item-text-c {
      color: #8F92A9;
      font-size: 13px;
      position: absolute;
      left: 178px;
      top: 68px;
      line-height: 19px;
    }
    .log-btn {
      text-align: center;
      width: 90px;
      height: 26px;
      line-height: 26px;
      color: #fff;
      background: #A4BBFF;
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 16px 0 16px 0;
    }
  }
  .time-box {
    margin-top: 22px;
    padding-bottom: 24px;
    border-bottom: 1px dashed #ACAEBF;
    text-align: center;
    .text-a {
      line-height: 23px;
      font-size: 16px;
      color: #3D4258;
    }
    .text-b {
      line-height: 31px;
      font-weight: 500;
      color: #4A77FF;
      font-size: 22px;
    }
  }
  .van-cell {
    padding: 10px 0;
    font-size: 16px;
    .cell__title {
      font-weight: 400;
      color: #3D4258;
    }
    .van-image {
      width: 20px;
      height: 20px;
    }
    .van-cell__value {
      font-weight: 500;
      color: #3D4258;
      height: 24px;
    }
  }
  .van-cell::after {
    border: 0;
  }
  .box-a {
    border-bottom: 1px dashed #ACAEBF;
  }
  .box-btn {
    width: 100%;
    color: #8F92A9;
    font-size: 16px;
    line-height: 26px;
    position: fixed;
    bottom: 29px;
    text-align: center;
    left: 0;
  }
  .popup-box {
    width: 260px;
    padding: 24px 30px 14px;
    background: #fff;
    text-align: center;
    .van-image {
      width: 200px;
      height: 200px;
    }
    .text-a {
      line-height: 23px;
      margin-top: 18px;
      color: #3D4258;
      font-size: 16px;
    }
  }
}
</style>
